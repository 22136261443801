// constants
$breakpoints: (
    'sm': 576px,
    'md': 768px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1400px
);

$blue: #215680;
$green: #769c38;
$purple: #f0eaff;

@mixin width-gt($breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content
    }
}

@mixin print-only {
    @media print {
        @content
    }
}

// origin of color filters is https://codepen.io/sosuke/pen/Pjoqqp
@mixin no-filter() {
    filter: none;
}

@mixin blue-filter() {
    filter: invert(29%) sepia(16%) saturate(2279%) hue-rotate(165deg) brightness(93%) contrast(87%);
}

@mixin white-filter() {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(275deg) brightness(105%) contrast(102%);
}

@mixin gray-filter() {
    filter: invert(57%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(91%) contrast(88%);
}

@mixin faq-gray-filter() {
    filter: invert(44%) sepia(3%) saturate(1386%) hue-rotate(186deg) brightness(94%) contrast(92%);
}

// overall styling
body {
    margin: 0;

    font-size: 18px;
    line-height: 33px;

    @include width-gt('lg') {
        font-size: 20px;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: bold;
}

.bg-white {
    color: black;

    h1, h2, h3, h4, h5, h6 {
        color: $blue;
    }
}

.main {
    background: #215680;
    color: white;
    min-height: 100vh;
    font-family: Arial, Helvetica, sans-serif;

    &.with-form-continue {
        padding-bottom: 100px;
    }

    &:not(.no-watermark) {
        background: linear-gradient(to bottom, #215680, #008aa9 85%);

        #probability-of-survival-tool {
            @include width-gt('lg') {
                background-image: url(/Static/images/watermark.png);
                background-position: top right;
                background-size: 400px;
                background-repeat: no-repeat;
            }
        }
    }
}

div, button, input, form {
    box-sizing: border-box;
}

.mb-2 {
    margin-bottom: 15px;
}

.mb-4 {
    margin-bottom: 30px;
}

.mb-6 {
    margin-bottom: 45px;
}

.p-3 {
    padding: 25px;
}

.p-4 {
    padding: 50px;
}

.px-4 {
    padding-left: 25px;
    padding-right: 25px;
}

.flex-column {
    flex-direction: column;
}

@include width-gt('lg') {
    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }
}

// utilities
.flex-spacer, .flex-grow-1 {
    flex-grow: 1;
}

.btn {
    font-family: 'Arial';
    font-weight: bold;
}

.btn-primary, .btn-primary:hover {
    &, &:hover {
        background-color: $green;
    }
}


.longevityillustrator-us {
    .btn-primary {
        &, &:hover {
            background-color: #67c197;
        }
    }
}

.btn-primary, .btn-secondary, .btn-outline {
    padding: 12px 25px;
}

.btn-outline {
    border: 1px solid white;
    background-color: transparent;
    color: white;
}

.btn-check {
    position: absolute;
    opacity: 0;

    &:checked {
        +.btn {
            color: $blue;
            border: 2px solid $blue;
            font-weight: bold;
            background-color: white;

            @include print-only() {
                border: none;
            }
        }
    }

    &:not(:checked) {
        +.btn {
            background-color: white;
            color: gray;
            border-color: gray;
            font-weight: normal;

            @include print-only() {
                display: none;
            }
        }
    }

    &:not(:last-child) {
        +.btn {
            margin-right: 10px;
        }
    }

    +.btn {
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        padding: 8px 32px;
        white-space: normal;
        border-radius: 6px;

        @include width-gt('lg') {
            width: auto;
        }
    }
}

.form-group {
    > label {
        font-size: 22px;
        font-weight: bold;
    }
}

.form-dropdown {
    position: relative;

    > select {
        appearance: none;
        border: none;
    }
    

    &::after {
        background-image: url('/Static/images/icons/arrow-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        height: 100%;
        @include blue-filter();
        position: absolute;
        width: 16px;
        right: 20px;
        top: 0;
        content: ' ';
        display: block;
        pointer-events: none;
    }
}

.form-control__narrow {
    max-width: 150px;
    text-align: center;
}

.tooltip-error {
    > .tooltip-inner {
        background-color: red;
    }

    > .arrow::before {
        border-bottom-color: red;
    }
}

.no-print {
    @include print-only() {
        display: none !important;
    }
}

.print-only {
    display: none;

    @include print-only() {
        display: unset !important;
    }
}

.col-print-12 {
    @include print-only() {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.no-print-break {
    @include print-only() {
        break-inside: avoid;
    }
}

.d-lg-none {
    @include width-gt('lg') {
        display: none !important;
    }
}

.d-lg-block {
    @include width-gt('lg') {
        display: block !important;
    }
}

// navbar
.navbar-wrap {
    padding: 25px;

    display: none;
    @include width-gt('lg') {
        display: block;

        @include print-only() {
            display: none !important;
        }
    }
}

.navbar {
    height: 64px;
    border-radius: 10px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;

    &__contents {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .navbar__logo {
            position: absolute;
            left: 0;
            top: 0;
            height: 64px;
        }

        .nav-list {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;;

            li {
                display: inline-block;
                height: 100%;
                margin-left: 10px;
                margin-right: 10px;

                a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: $blue;
                    font-weight: bold;
                    font-size: 18px;
                    &:hover {
                        color: $blue;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

$mobile-header-height: 50px;
.mobile-header {
    background-color: white;
    width: 100%;
    position: relative;
    height: $mobile-header-height;
    display: flex;
    align-items: center;
    justify-content: center;

    &__logo {
        height: 36px;
    }

    &__nav-button-wrap {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 10px;

        > button {
            background-color: transparent;
            border: none;

            > img {
                width: 24px;
                height: auto;
            }
        }
    }

    @include width-gt('lg') {
        display: none;
    }

    @include print-only() {
        display: none;
    }
}

.mobile-navbar {
    position: fixed;
    top: $mobile-header-height;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21000;
    display: flex;

    &__contents {
        width: 65%;
        height: 100%;
        background-color: white;

        .nav-list {
            padding-left: 0;
            list-style-type: none;

            li {
                a {
                    display: block;
                    padding: 15px 20px;
                    font-size: 16px;
                    color: #044c7a;
                    font-weight: bold;

                    &:hover {
                        text-decoration: none;
                    }
                }
                
            }
        }
    }

    &__background {
        background-color: rgba(0, 0, 0, 0.5);
        flex-grow: 1;
    }
}

// footer
.footer {
    background-color: #fcfcfc;
    color: #676c79;

    .footer__links {
        list-style-type: none;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;

            a {
                color: #676c79;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @include width-gt('lg') {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

@mixin terms-modal-width {
    left: 0;
    right: 0;
    
    @include width-gt('lg') {
        left: 15%;
        right: 15%;
    }
}

// terms modal
.terms-modal {
    --terms-modal-header-height: 60px;
    --terms-modal-footer-height: 140px;
    --terms-modal-top: 20px;
    --terms-modal-bottom: 20px;

    @include width-gt('lg') {
        --terms-modal-footer-height: 70px;
        --terms-modal-top: 10%;
        --terms-modal-bottom: 10%;
    }
   
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20010;

    &__header {
        @include terms-modal-width();

        top: var(--terms-modal-top);
        height: var(--terms-modal-header-height);
        border-radius: 14px 14px 0 0;
        position: absolute;
        background-color: white;
        
        display: flex;
        align-items: center;
        justify-content: end;

        .btn-close {
            background-color: transparent;
            border: none;
            margin-right: 20px;
            width: 20px;
            height: 20px;
            padding: 0;

            img {
                @include blue-filter();
            }
        }
    }

    &__body {
        @include terms-modal-width();

        position: absolute;
        z-index: 20020;
        background-color: white;
        color: black;
        opacity: 1;
        top: calc(var(--terms-modal-header-height) + var(--terms-modal-top));
        height: calc(100vh - var(--terms-modal-header-height) - var(--terms-modal-footer-height) - var(--terms-modal-top) - var(--terms-modal-bottom)); // the subtracted part needs to be the height of the header plus the height of the footer
        overflow: auto;
        font-size: 14px;

        .terms-modal__text {
            padding: 10px 50px;
            font-size: 14px;
            line-height: 22px;
            color: #343434;

            h2 {
                color: $blue;
                font-size: 25px;
            }

            h3 {
                color: $blue;
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 0;
            }

            p {
                margin-top: 0;
            }
        }
        
    }

    &__buttons {
        @include terms-modal-width();

        position: absolute;
        bottom: var(--terms-modal-bottom);
        height: var(--terms-modal-footer-height);
        background-color: white;
        
        display: flex;
        flex-wrap: wrap;
        @include width-gt('lg') {
            flex-wrap: nowrap;
        }

        padding: 10px;
        box-shadow: 0 -1px 20px 0 rgba(4,75,119,0.27);
        z-index: 20030;
        border-radius: 0 0 14px 14px;

        .terms-modal__button__caption {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 25px;
            padding-right: 25px;
            font-size: 16px;
            line-height: initial;

            color: black;
            width: 100%;
            text-align: center;
            @include width-gt('lg') {
                width: auto;
                text-align: center;
            }
        }

        .btn {
            max-height: 100%;
            margin-left: 10px;
            margin-right: 10px;

            padding-top: 8px;
            padding-bottom: 8px;

            width: 100%;
            @include width-gt('lg') {
                width: auto;
            }
        }
    }
}

// profile form
.person-number-badge {
    border-radius: 16px;
    padding: 6px 10px;
    width: fit-content;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: initial;

    &__one {
        background-color: #e9ffcd;
        color: #35550c;
    }

    &__two {
        background-color: #f1ebff;
        color: #623dc4;
    }
}

.longevityillustrator-us {
    .person-number-badge {
        &__one {
            background-color: #67c197;
            color: white;
        }

        &__two {
            background-color: #dd407c;
            color: white;
        }
    }
}

.profile-form-header {
    color: white;

    h2 {
        font-size: 33px;

        @include width-gt('lg') {
            font-size: 42px;
        }
    }

    a {
        color: white;
    }
}

.profile-form {
    background-color: white;
    border-radius: 14px;
    padding: 50px;
    margin-bottom: 40px;

    label {
        color: $blue;

        @include print-only() {
            color: black;
        }
    }

    input, select {
        &, &:active, &:focus {
            background-color: #F5F6F7;
            color: #383838;
        }
    }
}

.form-continue-footer {
    position: fixed;
    height: 100px;
    bottom: 0;
    background-color: white;
    left: 0;
    right: 0;
    box-shadow: 0 -1px 20px 0 rgba(162,162,162,0.27);
    display: flex;
    flex-direction: column;

    &__buttons {
        flex-grow: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        

        > button {
            height: 50px;
        }

        .go-back-button {
            position: absolute;
            left: 25px;
            border: 0;
            background-color: transparent;
            color: gray;

            width: 36px;
            height: 36px;
            padding: 0;

            img {
                @include blue-filter();
            }
        }
    }
    

    &__progress-bar {
        height: 10px;
        display: flex;
        width: 100%;

        > .completed-part {
            content: ' ';
            background-color: #9573EB;
        }

        > .incomplete-part {
            content: ' ';
        }
    }
}

.results-page-form-wrap {
    .results-page-form {
        background-color: white;
        padding: 15px;
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 10px;
        position: fixed;

        @include print-only() {
            position: static;
            width: 100% !important;
        }

        &__footer-wrap {
            width: 100%;
            position: relative;
            height: 10px;

            .results-page-form__footer {
                width: 100%;
                position: absolute;
                text-align: center;
            }
        }

        label {
            color: $blue;
            font-size: 14px;

            @include print-only() {
                color: black;
            }
        }

        .btn-check + .btn {
            padding: 5px 21px;
        }

        input, select {
            background-color: #f5f6f7;
            color: #383838;
            padding: 5px 13px;
            height: 30px;
            font-size: 14px;

            &:disabled {
                background-color: #d0d0d0;
                color: #202020;
            }
        }

        .form-group {
            margin-bottom: 3px;
        }

        label {
            margin-bottom: 0;
            line-height: initial;
        }

        .info-tooltip {
            img {
                width: 10px;
            }
        }
    }

    @include print-only() {
        display: block !important;
        z-index: 0;
        margin-left: 0;
        margin-right: 0;
    }

    margin-left: 15px;
    margin-right: 15px;
}

.results-page-form-mobile {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: white;
    z-index: 20000;
    padding: 30px;
    overflow-y: auto;

    label {
        color: $blue;
        font-size: 20px;
        font-weight: bold;
    }

    input, select {
        background-color: #f5f6f7;
        color: #383838;
    }

    $results-page-footer-height: 70px;
    .results-page-form__header {
        .btn-close {
            background-color: transparent;
            border: none;
    
            > img {
                width: 18px;
                height: 18px;
                @include blue-filter();
            }
        }

        margin-bottom: 5px;
    }

    .results-page-form {
        padding-bottom: $results-page-footer-height;
    }

    .results-page-form-mobile__footer {
        position: fixed;
        height: $results-page-footer-height;
        background-color: white;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0 -1px 20px 0 rgba(4,75,119,0.27);
        padding-top: 12px;
        padding-bottom: 12px;

        > button {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.remove-person-button {
    background-color: transparent;
    border: none;
    color: $blue;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
}

.add-person-button {
    background-color: transparent;
    border: none;
    color: $blue;
    font-size: 14px;
    font-weight: bold;

    > img {
        width: 16px;
        @include blue-filter();
    }
}

// results page
.results-table-wrap {
    max-width: 100%;
    overflow-x: auto;
    border: 1px solid white;
    border-radius: 5px;
}

.results-table {
    thead {
        font-size: 16px;
        background-color: #295881;
    }

    tbody {
        font-size: 14px;
        td {
            &:not(:last-child) {
                border-right: 1px solid white;
            }
        }

        tr {
            &:nth-child(2n) {
                background-color: #295881;
            }

            &:nth-child(2n+1) {
                background-color: #1e4c77;
            }
        }
    }

    border-radius: 14px;
    margin-bottom: 0;
    line-height: initial;
}

.results-page {
    margin-top: 10px;
    margin-bottom: 100px;

    h2 {
        font-size: 33px;

        @include width-gt('lg') {
            font-size: 45px;
        }
    }

    .planning-horizon-heading {
        font-size: 27px;
    }

    #helpful-hints {
        h2 {
            font-size: 18px;
        }
    }

    h3 {
        font-size: 27px;
    }

    h4 {
        font-size: 20px;
    }

    a {
        color: white;
        text-decoration: underline;
    }
}

.results-page-footer {
    position: fixed;
    background-color: white;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;

    &__person-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .btn {
            margin-top: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        position: relative;

        &:not(:last-child) {
            &::after {
                content: ' ';
                position: absolute;
                top: 3px;
                bottom: 3px;
                width: 1px;
                background-color: lightgray;
                right: 0;
            }
        }
    }

    @include width-gt('lg') {
        display: none;
    }
}

.print-button {
    > img {
        width: 14px;
        @include white-filter();
    }
}

// FAQ
.faq {
    border-radius: 14px;
    background-color: white;

    &__header {
        > button {
            color: #676C79;
            padding: 10px;
            display: flex;
            align-items: center;
            font-weight: normal;

            &:hover, &:active, &:focus {
                text-decoration: none;
                color: #676C79;
            }

            &.expanded {
                .faq__question-body {
                    color: $blue;
                    font-weight: bold;
                }

                &:hover, &:active, &:focus {
                    .faq__question-body {
                        color: $blue;
                    }
                }

                .faq__expand-icon {
                    > img {
                        @include blue-filter();
                    }
                }
            }

            .faq__expand-icon {
                margin-left: 10px;
                margin-right: 10px;
                padding: 0;
                align-self: top;

                > img {
                    width: 24px;
                    height: 24px;
                    @include faq-gray-filter();
                }
            }

            .faq__question {
                max-width: 100%;

                .faq__question-body {
                    max-width: 100%;
                    overflow-wrap: break-word;
                    white-space: normal;
                }
            }
        }
    }

    &__body {
        padding-left: 62px;
        padding-right: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #676C79;
        font-size: 18px;
        line-height: 33px;
    }
}

.results-page {
    .faq {
        background-color: transparent;
        color: white;
        border: 1px solid white;

        &__header {
            > button {
                &, &:hover, &:active, &:focus {
                    .faq__question-body {
                        color: white;
                    }
    
                    .faq__expand-icon {
                        > img {
                            @include white-filter();
                        }
                    }
    
                    &.expanded, &.expanded:hover {
                        color: white;
    
                        .faq__expand-icon {
                            > img {
                                @include white-filter();
                            }
                        }
                    }
                }
                
            }
        }

        &__body {
            color: white;
        }
    }
}

// info tooltips
.info-tooltip {
    > img {
        @include white-filter();
        width: 14px;
        height: 14px;
    }

    &.info-tooltip-gray {
        > img {
            @include gray-filter();
        }
    }

    color: $blue;
}

// intro
.intro {
    h2 {
        line-height: 52px;
    }

    p {
        font-size: 20px;
        line-height: 36px;
        color: #343434;
    }

    .steps-wrap {
        background-color: #044b77;

        @include width-gt('lg') {
            padding-bottom: 200px;
        }

        h2 {
            margin-top: 20px;
            margin-bottom: 25px;

            @include width-gt('lg') {
                margin-top: 40px;
                margin-bottom: 45px;
            }
        }
    
        .stepbox {
            &__image {
                max-width: 130px;
                margin-top: 50px;
                margin-bottom: 30px;
            }
        
            h3 {
                font-size: 23px;   
            }
        
            p {
                color: white;
            }
        }
    }
}

.hero-gradient-bg {
    background: linear-gradient(to left, #044f7a 0%, #028eab 49%,#037298 80%, #003157 100%);
}

.narrow {
    @include width-gt('lg') {
        padding-left: 100px;
        padding-right: 100px;
    }

    @include width-gt('xl') {
        padding-left: 140px;
        padding-right: 140px;
    }
}